<template>
  <div class="body-page">
    <div class="container-fluid chat_container">
      <div class="row">
        <!-- div class="col-md-12">
          <div class="row" v-if="authenticated">
            <Rooms />
            <Video :username="username" />
          </div>
          <div class="row" v-else>
            <div class="username">
              <form
                class="form-inline"
                @submit.prevent="submitUsername(username)"
              >
                <div class="form-group mb-2">
                  <input type="text" class="form-control" v-model="username" />
                </div>
                <button type="submit" class="btn btn-primary mb-2 Botton">
                  Entra
                </button>
              </form>
            </div>
          </div>
        </div -->
      </div>
    </div>
  </div>
</template>

<script>
// import Rooms from "@/components/common/twilio/Rooms";
// import Video from "@/components/common/twilio/Video";

export default {
  name: "Home",
  components: {
    // twilio demo
    /*
    Rooms,
    Video
    */
  },
  data() {
    return {
      // twilio demo
      /*
      authenticated: false,
      username: ""
      */
    };
  },
  methods: {
    // twilio demo
    /*
    submitUsername(username) {
      if (!username) {
        return alert("please provide a username");
      }
      this.authenticated = true;
    }
    */
  }
};
</script>
